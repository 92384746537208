import { useMemo, useState } from 'react'

import { createFilterOptions } from 'app/utils/helpers/createFilterOptions'

const defaultFilterOptions = createFilterOptions({
  stringify: (option) => option?.label,
  matchFrom: 'start'
})

const sortingOptions = ({ options, groupBy }) => {
  if (!groupBy) {
    return options.sort((a, b) => a?.label?.localeCompare(b?.label))
  }

  return options.sort((a, b) => a?.group?.localeCompare(b?.group) || 0)
}

type AutoCompleteProps = {
  filterOptions?: (
    options: Option[],
    { inputValue }: { inputValue: string }
  ) => Option[]
  groupBy?: (option: any) => string
  options: Option[]
  sortOption?: boolean
}

interface GroupedOptions extends Option {
  group: string
  firstInGroup?: boolean
}

const useAutoComplete = ({
  filterOptions = defaultFilterOptions,
  groupBy,
  sortOption = false,
  options
}: AutoCompleteProps) => {
  const [inputValue, setInputValue] = useState('')

  const groupedOptions = useMemo(() => {
    const filteredOptions = filterOptions(options, {
      inputValue
    })

    const groups = groupBy
      ? filteredOptions?.reduce((acc, option) => {
          const group = groupBy(option)
          const groupIndex = acc.findIndex(
            (groupItem) => groupItem.group === group
          )

          if (groupIndex === -1) {
            acc.push({
              group,
              firstInGroup: true,
              ...option
            })
          } else {
            acc.push({
              group,
              ...option
            })
          }
          return acc
        }, [] as GroupedOptions[])
      : filteredOptions

    const currentOptions = sortOption
      ? sortingOptions({ options: groups, groupBy })
      : groups

    return currentOptions
  }, [options, groupBy, inputValue, filterOptions])

  return {
    inputValue,
    setInputValue,
    options: groupedOptions
  }
}

export default useAutoComplete
